import styled from "styled-components";

export const LoaderWrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	@keyframes moveUpDown {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(
				-10px
			); 
		}
	}
	span {
		font-family: ${(props) => props.theme.font.title};
		font-size: 30px;
		letter-spacing: 5px;
        &:nth-child(1){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0s
        }
         &:nth-child(2){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.1s
        }
         &:nth-child(3){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.2s
        }
         &:nth-child(4){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.3s
        }
         &:nth-child(5){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.4s
        }
         &:nth-child(6){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.5s
        }
         &:nth-child(7){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.6s
        }
         &:nth-child(8){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.7s
        }
         &:nth-child(9){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.8s
        }
         &:nth-child(10){
            animation: moveUpDown 0.7s alternate infinite;
            animation-delay: 0.9s
        }
	}
	
`;
