export const ItemDetailDataEn = {
    cart: {
        title: "Your shopping cart",
        empty: "Empty cart",
        delete: "Delete",
        delete_selected: "Delete selected",
        subtotal: "Subtotal:",
        pay: "Proceed to checkout",
    },
    beer: {
        description: "Description",
        history: "History of",
        ingredients: "Ingredients",
        nutritional: {
            title: "Nutritional information",
            size: "Serving size",
            energy: "Energy",
        },
    },
    merch: {},
};

export const dataCheckoutEn = {
    title: "Order Summary",
    user_data: {
        title: "YOUR INFORMATION",
        label_1: "Full Name*",
        label_2: "Email Address*",
    },
    billing_data: {
        title: "BILLING DETAILS",
        label_1: "Address",
        label_2: "Zip Code",
        label_3: "City",
        label_4: "State/Province",
        label_5: "Country",
        label_6: "Mobile Phone",
        label_7: "ID Number",
    },
    resume: {
        title: "ORDER SUMMARY",
    },
    empty: "Your basket is empty",
    advice: "*Shipping cost is not included in the current total. In the next step, you will be able to select the shipping option that best suits your needs and see the associated cost.",
    total: {
        text_1: "We will process your personal data in accordance with the",
        text_2: "Privacy Policy of Es Secret Brewing.",
        text_3: "By continuing, you accept the ",
        text_4: "General Terms and Conditions of Es Secret Brewing.",
        button: "proceed to payment",
    },
    success: {
        title: "THANK YOU FOR YOUR PURCHASE",
        subtitle_1: "We are now processing your order",
        button: "Continue Shopping",
    },
    failed: {
        title: "WE CANNOT PROCESS YOUR PAYMENT",
        subtitle_1: "Please try again or contact us at ",
        subtitle_2: "hello@elsecretobrewing.com",
        subtitle_3: " for more information.",
        button: "Return to Shop",
    },
    canceled: {
        title: "ORDER CANCELLED",
        subtitle_1: "If you wish, you can continue adding products to your basket",
        button: "Return to Shop",
    },
    errors: {
        name: "*This field is required",
        lastName: "*This field is required",
        email: "*This field is required",
        address: "*This field is required",
        zipCode: "*This field is required",
        city: "*This field is required",
        province: "*This field is required",
        country: "*This field is required",
        phone: "*This field is required",
        dni: "*This field is required",
        cart: "*Add some items to your cart to get started",
        generic: "*Please fill in all the required fields to continue",
        emailFormat: "*The format is incorrect",
    },
};