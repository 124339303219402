import styled from "styled-components";

const CartWrapper = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.color.white};
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    box-shadow: 0px 3.32383px 15.53971px rgba(0, 0, 0, 0.3);
    .cart_title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: 80px;
        padding: 0;
        margin: 0;
        h2 {
            margin: 0;
            padding: 0;
            color: ${({ theme }) => theme.color.darkGreen};
            text-transform: uppercase;
            font-weight: 100;
            font-size: 25px;
            font-family: ${({ theme }) => theme.font.title};
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .cart_button {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            color: ${({ theme }) => theme.color.darkGreen};
            font-weight: 800;
            transform: scaleX(1.5);
            translate: -7px 0px;
            font-size: 22px;
            font-family: ${({ theme }) => theme.font.text};
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: ${({ theme }) => theme.color.yellow};
            }
        }
    }
    .cart_products {
        color: ${({ theme }) => theme.color.darkGreen};
        display: flex;
        flex-direction: column;
        align-items: center;

        height: auto;
        width: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            //background-color: rgba(255, 0, 0, 0.3);
            background-color: ${({ theme }) => theme.color.lightGreen};
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.color.green};
            border-radius: 5px;
            transition: 0.3s;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: ${({ theme }) => theme.color.green};
        }

        &::-webkit-scrollbar-corner {
            border-radius: 5px;
        }

        .empty_cart {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            h3 {
                font-size: 18px;
                font-weight: 100;
                font-family: ${({ theme }) => theme.font.text};
            }
        }
        .product_display {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid ${(props) => props.theme.color.darkGreen};
            &:first-of-type {
                border-top: 1px solid ${(props) => props.theme.color.darkGreen};
            }
            .checkbox {
                background-color: ${(props) => props.theme.color.white};
                border: 1px solid ${(props) => props.theme.color.black};
                transition: background-color 0.3s ease-in-out;
                appearance: none;
                width: 15px;
                height: 15px;
                border-radius: 5px;
                &:checked {
                    background-color: ${(props) => props.theme.color.lightGreen};
                }
            }
            .cart_image {
                position: relative;
                width: 20%;
                height: 80%;
            }
            .product_info {
                width: 40%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 20px;
                .titles {
                    p {
                        &:first-of-type {
                            font-family: ${(props) => props.theme.font.title};
                            text-transform: uppercase;
                            font-size: 20px;
                            font-weight: 100;
                        }
                        &:nth-child(2) {
                            font-family: ${(props) => props.theme.font.title};
                            text-transform: uppercase;
                            font-size: 14px;
                        }
                    }
                }

                .product_size {
                    margin: 0;
                    margin-bottom: 1%;
                    padding: 0;
                    font-size: 18px;
                    font-weight: 100;
                    font-family: ${(props) => props.theme.font.text};
                }

                .add_delete_button {
                    display: flex;
                    border: ${(props) => `1px solid ${props.theme.color.darkGreen}`};
                    align-items: center;
                    justify-content: center;
                    background-color: ${(props) => props.theme.color.white};
                    width: fit-content;
                    height: 30px;
                    border-radius: 5px;
                    p {
                        color: ${(props) => props.theme.color.black};
                        width: 40px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 100;
                        font-family: ${(props) => props.theme.font.text};
                    }
                    button {
                        border: none;
                        background: none;
                        color: ${(props) => props.theme.color.darkGreen};
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                        font-size: 18px;
                        width: 40px;
                        border-radius: 5px;
                        &:hover {
                            background-color: ${(props) => props.theme.color.lightGreen};
                            color: ${(props) => props.theme.color.black};
                        }
                    }
                }
            }
            .price_delete {
                width: 20%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 20px;
                p {
                    font-family: ${(props) => props.theme.font.text};
                    font-size: 17px;
                    align-self: end;
                }
                button {
                    background-color: ${(props) => props.theme.color.lightGreen};
                    border: none;
                    text-transform: uppercase;
                    height: 30px;
                    border-radius: 5px;
                    color: ${(props) => props.theme.color.black};
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        background-color: ${(props) => props.theme.color.mediumGreen};
                        color: ${(props) => props.theme.color.white};
                    }
                }
            }
        }
    }
    .subtotal {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px 0px;
        margin: 0;
        .top {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            height: 100%;
            h3 {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                color: ${(props) => props.theme.color.darkGreen};
                font-family: ${(props) => props.theme.font.text};
                font-size: 22px;
                font-weight: 100;
                height: 100%;
            }
            .subtotal_price {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 90%;
                button {
                    background: transparent;
                    border: none;
                    color: ${(props) => props.theme.color.mediumGreen};
                    text-transform: uppercase;
                    font-size: 13px;
                    transition: 0.3s;
                    cursor: pointer;
                    border-bottom: ${(props) => `1px solid ${props.theme.color.white}`};
                    &:hover {
                        border-bottom: ${(props) => `1px solid ${props.theme.color.darkGreen}`};
                        color: ${(props) => props.theme.color.darkGreen};
                    }
                }
            }
        }
        .pay_button {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;

            .pay {
                width: 100%;
                background: ${(props) => props.theme.color.mediumGreen};
                border-radius: 5px;
                justify-content: center;
                display: flex;
                align-items: center;
                color: white;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 100;
                transition: 0.3s;
                font-family: ${(props) => props.theme.font.text};
                &:hover {
                    background: ${(props) => props.theme.color.lightGreen};
                    color: black;
                }
            }
        }
    }
    @media screen and (max-width: 850px) {
        padding: 10px 20px;
        .cart_title {
            h2 {
                height: 100%;
                font-size: 16px;
            }
            .cart_button {
                height: 100%;
                font-size: 18px;
            }
        }
        .subtotal {
            .top h3 {
                font-size: 18px;
            }
            .top button {
                text-align: left;
                font-size: 12px;
            }
            .pay_button {
                justify-content: center;
                width: 100%;
                .pay {
                    width: 100%;
                }
            }
        }
        .cart_products {
            .empty_cart h3 {
                font-size: 20px;
            }
            .product_display {
                .product_info {
                    width: 35%;
                    .titles {
                        height: 90px;
                        p {
                            &:first-of-type {
                                font-family: ${(props) => props.theme.font.title};
                                text-transform: uppercase;
                                font-size: 16px;
                                font-weight: 100;
                            }
                            &:nth-child(2) {
                                font-family: ${(props) => props.theme.font.title};
                                text-transform: uppercase;
                                font-size: 14px;
                            }
                        }
                    }

                    .add_delete_button {
                        width: fit-content;
                        p {
                            color: ${(props) => props.theme.color.black};
                            width: 30px;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 100;
                            font-family: ${(props) => props.theme.font.text};
                        }
                        button {
                            font-size: 18px;
                            height: 100%;
                            width: 30px;
                        }
                    }
                }
                .price_delete {
                    width: 25%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    p {
                        font-family: ${(props) => props.theme.font.text};
                        font-size: 14px;
                    }
                    button {
                        height: 30px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
`;

export default CartWrapper;
