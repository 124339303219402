import styled from "styled-components";

export const FooterWrapper = styled.footer`
    width: 100%;
    height: 300px;
    padding: 0px 5%;
    margin-top: 4%;
    display: flex;
    position: relative;

    .logo {
        height: 100%;
        width: 20%;
        position: relative;
        img {
        }
    }
    .content {
        width: 80%;
        height: 80%;
        padding-left: 5%;
        font-family: ${(props) => props.theme.font.text};
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .upper_links {
            display: flex;
            justify-content: space-between;
            .category {
                display: flex;
                flex-direction: column;
                h2 {
                    font-family: ${(props) => props.theme.font.text};
                    text-transform: uppercase;
                    font-weight: ${(props) => props.theme.fontWeight.light};
                    margin-bottom: 10px;
                }
                a {
                    text-decoration: none;
                    font-family: ${(props) => props.theme.font.text};
                    color: ${(props) => props.theme.color.darkGreen};
                    line-height: 25px;
                    transition: 0.3s;
                    &:hover {
                        color: ${(props) => props.theme.color.yellow};
                    }
                }
            }
        }
        .bottom {
            width: 100%;
            position: relative;

            .external_links {
                margin-bottom: 15px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                a {
                    text-decoration: none;
                    font-family: ${(props) => props.theme.font.text};
                    color: ${(props) => props.theme.color.darkGreen};
                    transition: 0.3s;
                    &:hover {
                        color: ${(props) => props.theme.color.yellow};
                    }
                }
            }
            .lower_links {
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: -20px;
                width: 100%;
                a {
                    text-decoration: none;
                    font-family: ${(props) => props.theme.font.text};
                    color: ${(props) => props.theme.color.darkGreen};
                    line-height: 25px;
                    transition: 0.3s;
                    &:hover {
                        color: ${(props) => props.theme.color.yellow};
                    }
                    &:first-of-type {
                        pointer-events: none;
                    }
                }
            }
        }
    }
    .mobile_divider {
        display: none;
    }
    @media screen and (max-width: 769px) {
        height: 350px;
        flex-direction: column;
        background: ${(props) => props.theme.color.white};
        padding-bottom: 50px;
        .logo {
            width: 50%;
            height: 50%;
            display: flex;
            margin-left: 0%;
            img {
                object-position: left;
            }
        }
        .content {
            width: 100%;
            padding-left: 0%;
            height: 50%;
            align-items: flex-start;
            .upper_links {
                flex-direction: column;
                align-items: flex-start;
                .category {
                    margin: 10px 0px;
                    width: 100%;
                    align-items: flex-start;

                    h2 {
                        font-size: 18px;
                    }
                    a {
                        font-size: 14px;
                        text-align: left;
                    }
                }
            }

            .bottom {
                .external_links,
                .lower_links {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .lower_links {
                    bottom: 35px;
                    a {
                        font-size: 14px;
                        text-align: center;
                    }
                }
                .external_links {
                    margin-bottom: 140px;
                    a {
                        font-size: 14px;
                        text-align: center;
                        margin: 5px 0px;
                    }
                }
            }
            .mobile_divider {
                display: flex;
                width: 100%;
                height: 1px;
                background: black;
                margin-top: 50px;
                margin-bottom: 20px;
            }
        }
    }
`;
