import React, { useState } from "react";
import { NavbarWrapper } from "./Navbar.style";
import Link from "next/link";
import Image from "next/image";
import { Language } from "../Language/Language";
import { useRouter } from "next/router";
import { useAppState } from "@/context/AppProvider";
import Cart from "../Cart/Cart";
import { motion } from "framer-motion";
import useCart from "@/hooks/useCart/useCart";
import { ItemDetailDataEs } from "@/data/ShopData/esp";
import { ItemDetailDataCa } from "@/data/ShopData/ca";
import { useWindowSize } from "@uidotdev/usehooks";
import { ItemDetailDataEn } from "@/data/ShopData/en";
import { IconMenu } from "@tabler/icons-react";

export const Navbar = ({ data }: any) => {
    const { cartOpen, setCartOpen } = useAppState();
    const { cartState } = useCart();
    const { locale } = useRouter();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const cartVariants = {
        open: {
            x: 0,
            opacity: 1,
            transition: {
                type: "tween",
            },
        },
        closed: {
            x: 600,
            opacity: 0,
            transition: {
                type: "tween",
            },
        },
    };
    return (
        <NavbarWrapper cartOpen={cartOpen}>
            <Link href={"/"} className="logo">
                <Image fill priority src={"/assets/images/el-secreto-logo.svg"} alt="El Secreto" />
            </Link>
            {
                <>
                    <div className="items">
                        {data?.navbar_item?.map((item: { id: number; label: string; link: string }) => {
                            return (
                                <Link href={item?.link || "/"} className="underline" key={item?.id} onClick={() => setCartOpen(false)}>
                                    {item?.label}
                                </Link>
                            );
                        })}
                    </div>
                    <div className="mobile_language">
                        <div className="cart_button_mobile" onClick={() => setCartOpen(!cartOpen)}>
                            <Image src={"/assets/images/icons/carrito.svg"} alt="cart" width={25} height={25} />
                            <div className="info_products">{cartState?.products?.length}</div>
                        </div>
                        <Language className="language_mobile" />
                        <button className="mobile_menu_trigger" onClick={() => setMenuOpen(!menuOpen)}>
                            <IconMenu size={25} strokeWidth={2} color="#161B07" />
                        </button>
                    </div>
                    <Language className="language" />
                    <div className="cart_button" onClick={() => setCartOpen(!cartOpen)}>
                        <Image src={"/assets/images/icons/carrito.svg"} alt="cart" width={25} height={25} />
                        <div className="info_products">{cartState?.products?.length}</div>
                    </div>
                    {cartOpen && (
                        <>
                            <motion.div className="cart" initial="closed" animate="open" exit="closed" variants={cartVariants}>
                                <Cart
                                    setOpen={setCartOpen}
                                    data={
                                        locale === "es" ? ItemDetailDataEs?.cart : locale === "en" ? ItemDetailDataEn?.cart : ItemDetailDataCa?.cart
                                    }
                                />
                            </motion.div>
                            <div className="backdrop" onClick={() => setCartOpen(false)}></div>
                        </>
                    )}
                    {menuOpen && (
                        <div className="mobile_menu">
                            <button className="close" onClick={() => setMenuOpen(false)}>
                                X
                            </button>
                            <div className="mobile_menu_items">
                                {data?.navbar_item?.map((item: { id: number; label: string; link: string }) => {
                                    return (
                                        <Link className="navbar_item" href={item?.link || "/"} onClick={() => setMenuOpen(false)} key={item?.id}>
                                            {item?.label}
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </>
            }
        </NavbarWrapper>
    );
};
