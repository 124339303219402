import CartWrapper from "./Cart.style";

import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";
import { useState } from "react";
import { TypeProduct } from "../../redux/reducers/cartReducer/cartFunctions";
import useCart from "@/hooks/useCart/useCart";

const Cart = ({ setOpen, data }: { setOpen: React.Dispatch<React.SetStateAction<boolean>>; data: any }) => {
    const { cartState, addToCart, removeFromCart } = useCart();
    const [checkboxState, setCheckboxState] = useState<{ id: number; size?: string }[]>([]);

    const handleAddOne = (product: TypeProduct) => {
        addToCart({
            id: product?.id,
            name: product?.name,
            price: product?.price,
            price_id: product?.price_id,
            size: product?.size,
            quantity: 1,
            picture: product?.picture,
            category: product?.category,
        });
    };

    const handleDeleteOne = (product: TypeProduct) => {
        if (product?.quantity === 1) {
            removeFromCart(product?.id, product?.size);
        } else {
            addToCart({
                id: product?.id,
                name: product?.name,
                price: product?.price,
                price_id: product?.price_id,
                size: product?.size,
                quantity: -1,
                picture: product?.picture,
                category: product?.category,
            });
        }
    };

    const handleCheckbox = (e: any, productId: number, productSize?: string) => {
        e.target.checked && setCheckboxState([...checkboxState, { id: productId, size: productSize }]);

        !e.target.checked && setCheckboxState(checkboxState.filter((e) => e.id !== productId && e?.size !== productSize));
    };

    const deleteSelected = () => {
        for (let i = 0; i < checkboxState?.length; i++) {
            removeFromCart(checkboxState[i]?.id, checkboxState[i]?.size);
        }
        setCheckboxState([]);
    };

    return (
        <CartWrapper>
            <div className="cart_title">
                <h2>{data?.title}</h2>
                <button className="cart_button" onClick={() => setOpen(false)}>
                    X
                </button>
            </div>
            <div className="cart_products">
                {cartState?.products?.length !== 0 ? (
                    cartState?.products?.map((product: any, index: number) => (
                        <div key={index} className="product_display">
                            <input className="checkbox" type="checkbox" onChange={(e) => handleCheckbox(e, product?.id, product?.size)} />
                            <div className="cart_image">
                                <Image
                                    src={
                                        product?.category === "merch"
                                            ? `${process.env.NEXT_PUBLIC_STRAPI_URL}${product.picture.url[0]}`
                                            : product?.picture_with_background?.data
                                            ? `${process.env.NEXT_PUBLIC_STRAPI_URL}${product?.picture_with_background?.data?.attributes?.url}`
                                            : `${process.env.NEXT_PUBLIC_STRAPI_URL}${product?.picture?.data?.attributes?.url}`
                                    }
                                    alt="beer image"
                                    fill
                                    style={{ objectFit: "contain" }}
                                />
                            </div>
                            <div className="product_info">
                                <div className="titles">{<p>{product?.name}</p>}</div>

                                {product.size && <p className="product_size">{product.size}</p>}

                                <div className="add_delete_button">
                                    <button onClick={() => handleDeleteOne(product)}>-</button>
                                    <p>{product?.quantity}</p>
                                    <button onClick={() => handleAddOne(product)}>+</button>
                                </div>
                            </div>
                            <div className="price_delete">
                                <button onClick={() => removeFromCart(product?.id, product?.size)}>{data?.delete}</button>
                                <p>{product?.price}€</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="empty_cart">
                        <h3>{data?.empty}</h3>
                    </div>
                )}
            </div>
            <div className="subtotal">
                {cartState.products.length !== 0 && (
                    <div className="top">
                        <div className="subtotal_price">
                            <button className="basic_button delete" onClick={deleteSelected}>
                                {data?.delete_selected}
                            </button>
                        </div>
                        <h3>{`${data?.subtotal} ${cartState.price.toFixed(2)}€`}</h3>
                    </div>
                )}
                {cartState.products.length !== 0 && (
                    <div className="pay_button">
                        <Link className="pay" href={"/checkout"} onClick={() => setOpen(false)}>
                            {data?.pay}
                        </Link>
                    </div>
                )}
            </div>
        </CartWrapper>
    );
};

export default Cart;
