import type { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";

import { addProduct, removeProduct, TypeProduct } from "../../redux/reducers/cartReducer/cart";

const useCart = () => {
    const cartState = useSelector((state: RootState) => state.cart);
    const dispatch = useDispatch();

    const addToCart = (product: TypeProduct) => {
        dispatch(addProduct(product));
    };

    const removeFromCart = (id: number | string, size: string | undefined) => {
        dispatch(removeProduct({ id: id, size: size }));
    };

    const formatPrice = (price: number) => {
        const formattedPrice = price.toFixed(2);
        const [integer, decimals] = formattedPrice.split(".");
        const priceWithDots = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const finalPrice = priceWithDots + "." + decimals + "€";
        return finalPrice;
    };

    return {
        cartState,
        addToCart,
        removeFromCart,
        formatPrice,
    };
};

export default useCart;
