import styled from "styled-components";

export const NavbarWrapper = styled.nav<{ cartOpen: boolean }>`
    width: 100%;
    height: 120px;
    position: fixed;
    display: flex;
    padding: 0% 5%;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    @supports not ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
        .navbar {
            background-color: rgba(255, 255, 255, 0.6);
        }
    }
    .logo {
        width: 155px;
        position: relative;
        height: 100%;
        cursor: pointer;
        z-index: 260;
        margin-bottom: 10px;
    }
    .items {
        width: 40%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        margin: 27%;
        a {
            font-family: ${(props) => props.theme.font.title};
            text-transform: uppercase;
            text-decoration: none;
            color: ${(props) => props.theme.color.darkGreen};
            font-size: 22px;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                //color: ${(props) => props.theme.color.yellow};
            }
            @media screen and (max-width: 1200px) {
                font-size: 18px;
            }
            @media screen and (max-width: 920px) {
                font-size: 14px;
                font-weight: ${(props) => props.theme.fontWeight.regular};
            }
        }
        .underline {
            position: relative;
        }

        .underline::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background-color: ${(props) => props.theme.color.darkGreen};
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.3s ease-in-out;
        }

        .underline:hover::after {
            transform: scaleX(1);
            transform-origin: left;
        }
    }

    .cart_button {
        border: none;
        cursor: pointer;
        background: none;
        position: relative;
        height: 25px;
        width: 25px;
        margin-left: 20px;
        margin-top: 2px;
        .info_products {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 18px;
            height: 18px;
            background-color: ${(props) => props.theme.color.yellow};
            color: ${(props) => props.theme.color.darkGreen};
            font-family: ${(props) => props.theme.font.title};
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: ${(props) => props.theme.fontWeight.regular};
        }
        .notifications {
            position: relative;
            width: 30px;
            height: 30px;
            .hover-icon {
                display: none;
            }
            &:hover {
                .regular-icon {
                    display: none;
                }
                .hover-icon {
                    display: flex;
                }
            }
        }
        @media screen and (max-width: 769px) {
            display: none;
        }
    }
    .language {
        border: none;
        cursor: pointer;
        position: absolute;
        right: 3%;
        margin-right: 5%;
        @media screen and (max-width: 769px) {
            display: none;
        }
    }

    .mobile_language {
        display: none;
    }
    .cart {
        position: fixed;
        top: 0;
        right: 0;
        width: 600px;
        z-index: 260;
    }
    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        /*         background: ${(props) => (props.cartOpen ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.5)")};
 */
        z-index: 258;
        transition: all 0.3s ease-in-out;
    }

    @keyframes fadeIn {
        from {
            height: 0%;
        }
        to {
            height: 100vh;
        }
    }
    @keyframes fadeOut {
        from {
            height: 100vh;
        }
        to {
            height: 0%;
        }
    }
    @keyframes opacity {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .mobile_menu {
        animation: fadeIn 0.5s ease-in-out, opacity 0.8s ease-in-out;
        position: fixed;
        width: 100%;
        background: ${(props) => props.theme.color.green};
        height: 100vh;
        top: 0px;
        left: 0px;
        top: 0px;
        left: 0px;
        z-index: 250;
        padding: 40px 5%;
        .close {
            transform: scaleX(1.6);
            font-size: 22px;
            background: transparent;
            border: none;
            position: absolute;
            top: 40px;
            right: 26px;
            color: ${(props) => props.theme.color.darkGreen};
        }
        .mobile_menu_items {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 100px;
            font-size: 30px;
            .navbar_item {
                font-family: ${(props) => props.theme.font.title};
                text-transform: uppercase;
                text-decoration: none;
                color: ${(props) => props.theme.color.darkGreen};
                font-size: 30px;
                transition: 0.3s;
                margin: 10px 0px;
                &:hover {
                    cursor: pointer;
                    color: ${(props) => props.theme.color.yellow};
                }
            }
        }
    }

    @media screen and (max-width: 769px) {
        height: 80px;
        .mobile_language {
            display: flex;
            column-gap: 1%;
            .cart_button_mobile {
                border: none;
                cursor: pointer;
                background: none;
                position: relative;
                height: 25px;
                width: 25px;
                margin-top: 2px;
                margin-right: 8%;
                .language_mobile {
                    display: flex;
                }
                .info_products {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    width: 18px;
                    height: 18px;
                    background-color: ${(props) => props.theme.color.yellow};
                    color: ${(props) => props.theme.color.darkGreen};
                    font-family: ${(props) => props.theme.font.title};
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: ${(props) => props.theme.fontWeight.regular};
                }
                .notifications {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    .hover-icon {
                        display: none;
                    }
                    &:hover {
                        .regular-icon {
                            display: none;
                        }
                        .hover-icon {
                            display: flex;
                        }
                    }
                }
            }
            .mobile_menu_trigger {
                background: transparent;
                border: none;
                color: #161b07;
            }
        }
        .logo {
            width: 150px;
            height: 100%;
        }
        .items {
            display: none;
        }
        .cart_button {
            .notifications {
                width: 20px;
                height: 20px;
            }
        }
        .cart {
            width: 100%;
        }
    }
`;
