export const ItemDetailDataEs = {
    cart: {
        title: "Tu cesta de compra",
        empty: "Carrito vacío",
        delete: "Eliminar",
        delete_selected: "Eliminar seleccionados",
        subtotal: "Subtotal:",
        pay: "Tramitar pedido",
    },
    beer: {
        description: "Descripción",
        history: "La historia de",
        ingredients: "Ingredientes",
        nutritional: {
            title: "Información nutricional",
            size: "Tamaño de la porción",
            energy: "Energía",
        },
    },
    merch: {},
};

export const dataCheckoutEs = {
    title: "Resumen del pedido",
    user_data: {
        title: "TU INFORMACIÓN",
        label_1: "Nombre Completo*",
        label_2: "Correo electrónico*",
    },

    resume: {
        title: "RESUMEN DEL PEDIDO",
    },
    empty: "Tu carrito está vacío",

    advice: "*El coste de envío no está incluido en el total actual. En el próximo paso, podrás seleccionar la opción de envío que mejor se adapte a tus necesidades y ver el coste asociado.",
    total: {
        text_1: "Procesaremos tus datos personales conforme a la ",
        text_2: " Política de Privacidad de El Secreto Brewing.",
        text_3: "Al continuar, aceptas los ",
        text_4: " Términos y Condiciones Generales de El Secreto Brewing. ",
        button: "continuar con el pago",
    },
    success: {
        title: "GRACIAS POR TU COMPRA",
        subtitle_1: "Ya estamos procesando tu pedido",
        button: "Seguir comprando",
    },
    failed: {
        title: "NO PUDIMOS PROCESAR TU PAGO",
        subtitle_1: "Por favor inténtalo de nuevo o escríbenos a ",
        subtitle_2: "hello@elsecretobrewing.com",
        subtitle_3: " para más información.",
        button: "Volver a Shop",
    },
    canceled: {
        title: "ORDEN CANCELADA",
        subtitle_1: "Si lo deseas, puedes seguir añadiendo productos a tu carrito",
        button: "Volver a Shop",
    },
    errors: {
        name: "*Este campo es obligatorio",
        lastName: "*Este campo es obligatorio",
        email: "*Este campo es obligatorio",
        address: "*Este campo es obligatorio",
        zipCode: "*Este campo es obligatorio",
        city: "*Este campo es obligatorio",
        province: "*Este campo es obligatorio",
        country: "*Este campo es obligatorio",
        phone: "*Este campo es obligatorio",
        dni: "*Este campo es obligatorio",
        cart: "*Agrega algunos productos a tu carrito para comenzar",
        generic: "*Por favor completa todos los datos para continuar",
        emailFormat: "*El formato es incorrecto",
    },
};
