import React from "react";
import { LoaderWrapper } from "./Loader.style";

export const Loader = () => {
	return (
		<LoaderWrapper>
			<span>L</span>
			<span>o</span>
			<span>a</span>
			<span>d</span>
			<span>i</span>
			<span>n</span>
			<span>g</span>
			<span>.</span>
			<span>.</span>
			<span>.</span>
		</LoaderWrapper>
	);
};
