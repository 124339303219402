import React from "react";
import { FooterWrapper } from "./Footer.style";
import Image from "next/image";
import Link from "next/link";

export const Footer = ({ data }: any) => {
    return (
        <FooterWrapper>
            <div className="logo">
                <Image
                    src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data?.footer_logo?.data?.attributes?.url}`}
                    alt={data?.footer_logo?.data?.attributes?.alternativeText || ""}
                    fill
                    priority
                    style={{ objectFit: "contain" }}
                />
            </div>
            <div className="content">
                <div className="upper_links">
                    {data?.footer_upper_links?.map((link: any, index: any) => {
                        return (
                            <div className="category" key={index}>
                                <h2>{link?.title}</h2>
                                {link?.footer_upper_link?.map((link: any, index: any) => {
                                    return (
                                        <Link href={link?.link} key={index}>
                                            {link?.label}
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>

                <div className="bottom">
                    <div className="mobile_divider" />
                    <div className="external_links">
                        {data?.footer_external_links?.map((link: any) => {
                            return (
                                <Link href={link?.link} key={link?.label}>
                                    {link?.label}
                                </Link>
                            );
                        })}
                        {/* <a href="mailto:hello@elsecretobrewing.com">hello@elsecretobrewing.com</a> */}
                    </div>
                    <div className="lower_links">
                        {data?.footer_lower_items?.map((link: any) => {
                            return (
                                <Link href={link?.link} key={link?.label}>
                                    {link?.label}
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </FooterWrapper>
    );
};
