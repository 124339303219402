export const ItemDetailDataCa = {
    cart: {
        title: "La teva cistella de compra",
        empty: "Cistella buida",
        delete: "Eliminar",
        delete_selected: "Eliminar seleccionats",
        subtotal: "Subtotal:",
        pay: "Tramitar comanda",
    },
    beer: {
        description: "Descripció",
        history: "La història de",
        ingredients: "Ingredients",
        nutritional: {
            title: "Informació nutricional",
            size: "Tamany de la porció",
            energy: "Energia",
        },
    },
    merch: {},
};

export const dataCheckoutCa = {
    title: "Resum de la comanda",
    user_data: {
        title: "LA TEVA INFORMACIÓ",
        label_1: "Nom complet*",
        label_2: "Correu electrònic*",
    },
    billing_data: {
        title: "DADES DE FACTURACIÓ",
        label_1: "Adreça postal",
        label_2: "Codi postal",
        label_3: "Ciutat",
        label_4: "Província",
        label_5: "País",
        label_6: "Telèfon mòbil",
        label_7: "Número de DNI o NIE",
    },
    resume: {
        title: "RESUM DE LA COMANDA",
    },
    empty: "La teva cistella és buida",
    advice: "*El cost d'enviament no està inclòs al total actual. En el proper pas, podràs seleccionar l'opció d'enviament que millor s'adapti a les teves necessitats i veure el cost associat.",
    total: {
        text_1: "Processarem les teves dades personals d'acord amb la",
        text_2: "Política de Privadesa de Es Secret Brewing.",
        text_3: "En continuar, acceptes els",
        text_4: "Termes i Condicions Generals de Es Secret Brewing.",
        button: "Continuar amb el pagament",
    },
    success: {
        title: "GRÀCIES PER LA TEVA COMPRA",
        subtitle_1: "Ja estem processant la teva comanda",
        button: "Seguir comprant",
    },
    failed: {
        title: "NO PODEM PROCESSAR EL TEU PAGAMENT",
        subtitle_1: "Si us plau, intenta novament o escriu-nos a",
        subtitle_2: "hello@elsecretobrewing.com",
        subtitle_3: "per a més informació.",
        button: "Tornar a Shop",
    },
    canceled: {
        title: "COMANDA CANCEL·LADA",
        subtitle_1: "Si vols, pots continuar afegint productes a la teva cistella",
        button: "Tornar a Shop",
    },
    errors: {
        name: "*Aquest camp és obligatori",
        lastName: "*Aquest camp és obligatori",
        email: "*Aquest camp és obligatori",
        address: "*Aquest camp és obligatori",
        zipCode: "*Aquest camp és obligatori",
        city: "*Aquest camp és obligatori",
        province: "*Aquest camp és obligatori",
        country: "*Aquest camp és obligatori",
        phone: "*Aquest camp és obligatori",
        dni: "*Aquest camp és obligatori",
        cart: "*Afegeix alguns productes al teu carretó per començar",
        generic: "*Si us plau, completa totes les dades per continuar",
        emailFormat: "*El format és incorrecte",
    },
};
